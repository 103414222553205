import { createEvent, createStore, sample } from 'effector';

import { postContactFormMutation } from '@/shared/api/send-message/api';

import { ContactModalData } from './types';

export const contactFormDataSent = createEvent<ContactModalData>();
export const $isContactFormSentSuccessfully = createStore<boolean>(false);

sample({
  clock: contactFormDataSent,
  target: postContactFormMutation.start,
});

sample({
  clock: postContactFormMutation.finished.success,
  fn: () => true,
  target: $isContactFormSentSuccessfully,
});

export const onContactFormClose = createEvent();

sample({
  clock: onContactFormClose,
  fn: () => false,
  target: $isContactFormSentSuccessfully,
});
