import { z } from 'zod';

import { MAX_LENGTH_MESSAGE, MIN_LENGTH_MESSAGE, REQUIRED_FIELD_MESSAGE } from '@/shared/constants/error-messages';

export const contactFormSchema = z.object({
  name: z
    .string()
    .min(2, { message: MIN_LENGTH_MESSAGE })
    .max(250, { message: MAX_LENGTH_MESSAGE })
    .trim()
    .toLowerCase()
    .refine((value) => !!value, {
      message: REQUIRED_FIELD_MESSAGE,
      path: [],
    }),
  telegram: z
    .string()
    .min(2, { message: MIN_LENGTH_MESSAGE })
    .max(250, { message: MAX_LENGTH_MESSAGE })
    .trim()
    .toLowerCase()
    .refine((value) => !!value, {
      message: REQUIRED_FIELD_MESSAGE,
      path: [],
    }),

  source: z.enum(['social', 'search', 'linkedin', 'other'], {
    required_error: 'Выберите один из вариантов',
  }),

  otherValue: z.string().optional(),
});
