import { CSSProperties, FC, ReactNode } from 'react';

import { cn } from '@/shared/lib/cn';

interface TextShimmerAnimationProps {
  children: ReactNode;
  className?: string;
  shimmerWidth?: number;
}

export const TextShimmerAnimation = (props: TextShimmerAnimationProps) => {
  const { children, className, shimmerWidth = 50 } = props;

  return (
    <p
      style={
        {
          '--shimmer-width': `${shimmerWidth}px`,
        } as CSSProperties
      }
      className={cn(
        'mx-auto max-w-md text-brand-accent-dark text-opacity-80 dark:text-brand-accent-normal',

        // Shimmer effect
        'animate-shimmer bg-clip-text bg-no-repeat [background-position:0_0] [background-size:var(--shimmer-width)_100%] [transition:background-position_1s_cubic-bezier(.6,.6,0,1)_infinite]',

        // Shimmer gradient
        'bg-gradient-to-r from-transparent via-brand-accent-black via-100%  dark:via-brand-accent-superLight',

        className,
      )}
    >
      {children}
    </p>
  );
};
