import { SearchIcon } from '@/shared/components/icons/search-icon';
import { Button } from '@/shared/components/ui/button';
import { cn } from '@/shared/lib/cn';

interface ReviewCardProps {
  text: string;
  author?: {
    name?: string;
    profession?: string;
  };
  textClass?: string;
  className?: string;
  openFullReview?: (text: string) => void;
}

export const ReviewCard = (props: ReviewCardProps) => {
  const { text, author, textClass, className, openFullReview } = props;

  return (
    <div
      className={cn(
        'group relative flex w-72 shrink-0 cursor-pointer snap-center snap-always flex-col justify-between overflow-hidden rounded-xl border border-neutral-200 bg-white p-4 shadow-md shadow-black/[0.1] dark:bg-black dark:[border:1px_solid_rgba(255,255,255,.1)] dark:[box-shadow:0_-20px_80px_-20px_#ffffff1f_inset] sm:shadow-xl lg:min-w-96',
        className,
      )}
    >
      <div className="select-none text-base font-normal text-neutral-700 dark:text-neutral-400">
        <p className={cn({ [`${textClass}`]: textClass && textClass.length })}>{text}</p>
      </div>

      {author && (
        <div className="mt-4 flex select-none items-end justify-between">
          <div>
            <p className="font-medium">{author.name}</p>
            <p className="text-sm font-normal text-brand-accent-normal dark:text-brand-accent-light">
              {author.profession}
            </p>
          </div>
        </div>
      )}

      {openFullReview && (
        <Button
          variant="outline"
          size="icon"
          className="absolute -right-1 -top-1 flex place-items-center group-hover:visible dark:bg-black/80 sm:invisible"
          onClick={() => openFullReview(text)}
          aria-label="Раскрыть отзыв полностью"
        >
          <SearchIcon aria-hidden className="-mb-1 -ml-1 h-5 w-5" />
        </Button>
      )}
    </div>
  );
};
